<template>
<div>
  <v-row class="pa-4">
    <template v-if="$vuetify.breakpoint.mdAndUp">
      <v-col cols="12" sm="12" md="9" class="d-flex flex-wrap flex-row align-center">
        <div class="mt-2">
          <search-expand 
            v-model="search" 
            :load="loadingData"
            placeholder="Buscar administrativo"
          />
        </div>
        <div class="mt-2">
          <btn-filtros 
            v-model="filtrar.sesion"
            name="Sesión" 
            :items="items_filtro_sesion" 
          />
        </div>
        <div class="mt-2">
          <btn-filtros 
            v-model="filtrar.status_user"
            name="Estatus" 
            :items="items_filtro_status" 
          />
        </div>
        <div class="mt-2">
          <btn-filtros 
            v-model="filtrar.rol"
            name="Rol" 
            multiple
            :items="items_filtro_rol" 
          />
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="3" 
        class="d-flex"
        :class="{'justify-end': $vuetify.breakpoint.smAndUp, 'justify-center': $vuetify.breakpoint.xsOnly,}"  
      >
        <v-btn
          color="icono"
          small
          outlined
          class="ml-2 py-0 mt-4"
          :class="{'px-2': $vuetify.breakpoint.smAndDown}"
          @click="updateData"
        >               
          <v-icon 
            size="22" 
            :left="$vuetify.breakpoint.mdAndUp" 
            v-text="'mdi-update'"
          />             
          <span v-if="$vuetify.breakpoint.mdAndUp">Actualizar</span>          
        </v-btn>  
        <v-btn
          small
          color="icono"
          class="ml-2 py-0 mt-4"
          dark
          @click="crearUser"
        >
          <v-icon
            size="22" 
            :left="$vuetify.breakpoint.mdAndUp" 
          >
            mdi-account-plus
          </v-icon>
          Crear
        </v-btn>
      </v-col>
    </template>
    <template v-if="$vuetify.breakpoint.smAndDown">
      <v-col cols="12" sm="12" md="9" class="d-flex flex-wrap flex-row align-center">
          <search-expand 
            v-model="search" 
            :load="loadingData"
            placeholder="Buscar administrativo"
          />
      </v-col>
      <v-col cols="12" sm="8" class="d-flex flex-wrap align-center">
        <btn-filtros 
          v-model="filtrar.sesion"
          name="Sesión" 
          class="mb-2 ml-2"
          :items="items_filtro_sesion" 
        />
        <btn-filtros 
          v-model="filtrar.status_user"
          name="Estatus" 
          :items="items_filtro_status" 
        />
        <btn-filtros 
          v-model="filtrar.rol"
          name="Rol" 
          multiple
          :items="items_filtro_rol" 
        />
      </v-col>
      <v-col cols="4" v-if="$vuetify.breakpoint.smOnly" class="d-flex justify-end">
        <v-btn
          color="icono"
          small
          outlined
          class="ml-2 py-0"
          :class="{'px-2': $vuetify.breakpoint.smAndDown}"
          @click="updateData"
        >               
          <v-icon 
            size="22" 
            :left="$vuetify.breakpoint.mdAndUp" 
            v-text="'mdi-update'"
          />                      
        </v-btn>  
        <v-btn
          small
          color="icono"
          class="ml-2 py-0"
          dark
          @click="crearUser"
        >
          <v-icon
            size="22" 
            :left="$vuetify.breakpoint.mdAndUp" 
          >
            mdi-account-plus
          </v-icon>
          Crear
        </v-btn>
      </v-col>
      <v-col cols="6" v-if="$vuetify.breakpoint.xsOnly" class="d-flex align-center">
        <v-btn
          color="icono"
          small
          outlined
          block
          class="py-0"
          :class="{'px-2': $vuetify.breakpoint.smAndDown}"
          @click="updateData"
        >               
          <v-icon 
            size="22" 
            :left="$vuetify.breakpoint.mdAndUp" 
            v-text="'mdi-update'"
          />     
          Actualizar                 
        </v-btn>          
      </v-col>
      <v-col cols="6" v-if="$vuetify.breakpoint.xsOnly" class="d-flex align-center">       
        <v-btn
          small
          color="icono"
          class="py-0"
          dark
          block
          @click="crearUser"
        >
          <v-icon
            size="22" 
            :left="$vuetify.breakpoint.mdAndUp" 
          >
            mdi-account-plus
          </v-icon>
          Crear
        </v-btn>
      </v-col>
    </template> 
  </v-row>
  <v-divider />  
  <v-row class="pa-0 ma-0">
    <v-col cols="12" class="pa-0">
       <v-data-iterator
        :items="itemsUsersAdmin"
        item-key="name"
        :items-per-page="10"
        class="mx-3"
        :search="search"
        :loading="loadingData"
        v-if="$vuetify.breakpoint.mobile"
      >
        <template v-slot:default="{ items }">
          <template v-for="(user,i) in items">
            <list-mobile type-user="admin" :user="user" :key="'mobile-'+i">
              <template #action>
                <div class="text-right">
                  <v-menu
                  bottom
                  left
                  min-width="200"
                  offset-y
                  origin="top right"
                  transition="scale-transition"
                  >
                    <template v-slot:activator="{ attrs, on }">
                      <v-btn  
                        v-bind="attrs"
                        v-on="on" 
                        plain 
                        small 
                        color="icono" 
                        class="px-0"
                      >
                        <v-icon size="20"  color="icono">mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list
                      class="py-0"
                    >
                      <v-list-item-group
                        color="primary"
                      >
                        <v-list-item class="py-2" @click="gestionItem(user)">
                          <v-list-item-icon class="my-2 ml-1 mr-3 align-self-center">
                            <v-icon color="light-blue darken-3" size="22">
                              mdi-account-cog-outline
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>
                            <span>Gestionar Usuario</span>
                          </v-list-item-title>
                        </v-list-item>
                        <v-divider/>
                        <v-list-item class="py-2" @click="closeSesion(user)" :disabled="!user.sesion_activa">
                          <v-list-item-icon class="my-2 ml-1 mr-3 align-self-center">
                            <v-icon size="22" color="light-blue darken-3">mdi-lock</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Cerrar Sesión</v-list-item-title>
                        </v-list-item>      
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </div>
              </template>
            </list-mobile>
          </template>
        </template>
        <template #loading>
          <v-skeleton-loader
            v-bind="attrs"
            type="list-item-two-line, list-item, list-item-two-line"
          ></v-skeleton-loader>
        </template>
      </v-data-iterator>
      <v-data-table
        :headers="headers"
        :items="itemsUsersAdmin"
        :search="search"
        :loading="loadingData"
        disable-sort
        class="user-table"
        v-else
      >
        <template v-slot:item.ven_des="{ item }">
          <v-list-item class="px-0">
            <v-list-item-avatar class="mx-1">
              <v-icon
                color="primary"
                left
                class="mt-n1"
              >
                mdi-account-circle-outline
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span class="primary--text font-weight-bold">{{item.name}}</span>
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-chip
                label
                class="font-weight-medium mt-1"
                :color="item.sesion_activa ? 'icono' : 'red'"
                x-small
                dark
                v-text="item.sesion_activa ? 'Sesion Activa' : 'Sesion Cerrada'"
              />               
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:item.email="{ item }">
          <v-list-item class="px-0">           
            <v-list-item-content>
              <v-list-item-title>
                <v-icon small color="success" left>mdi-shield-check</v-icon>
                <span 
                  class="blue-grey--text font-weight-medium" 
                  v-text="item.email" 
                />
              </v-list-item-title>
              <v-list-item-subtitle class="mt-1">
                <v-chip label color="grey lighten-2" small class="mr-2" v-for="(rol,i) in item.roles" :key="'rol-'+i">
                  <span class="font-weight-medium"> {{rol.name}} </span>
                </v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:item.estatus="{ item }">
          <div class="text-center">
            <v-chip
              class="ma-2 font-weight-bold"
              :color="item.status === 1 ? 'success-alert' : 'disabled-alert' "
              small
              label
              pill
              dark
            >
              {{ item.status === 1 ? 'Activo' : 'Inactivo' }}
            </v-chip>            
          </div>
        </template>
        <template v-slot:item.ultimo_uso="{ item }">
          <v-icon v-if="item.ultimo_uso" small color="blue-grey" left class="mt-n1">mdi-alarm</v-icon>
          <span class="blue-grey--text">{{item.ultimo_uso | FullDate}}</span>
        </template>
        <template v-slot:item.actions="{ item }">          
          <div class="text-right">
            <v-menu
            bottom
            left
            min-width="200"
            offset-y
            origin="top right"
            transition="scale-transition"
            >
              <template v-slot:activator="{ attrs, on }">
                <v-btn  
                  v-bind="attrs"
                  v-on="on" 
                  plain 
                  small 
                  color="icono" 
                  class="px-0"
                >
                  <v-icon size="20"  color="icono">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list
                class="py-0"
              >
                <v-list-item-group
                  color="primary"
                >
                  <v-list-item class="py-2" @click="gestionItem(item)">
                    <v-list-item-icon class="my-2 ml-1 mr-3 align-self-center">
                      <v-icon color="light-blue darken-3" size="22">
                        mdi-account-cog-outline
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      <span>Gestionar Usuario</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-divider/>
                  <v-list-item class="py-2" @click="closeSesion(item)">
                    <v-list-item-icon class="my-2 ml-1 mr-3 align-self-center">
                      <v-icon size="22" color="light-blue darken-3">mdi-lock</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Cerrar Sesión</v-list-item-title>
                  </v-list-item>      
                </v-list-item-group>
              </v-list>
            </v-menu>
          </div>
        </template>
        <template #loading>
          <v-skeleton-loader
            v-bind="attrs"
            type="table-tbody"
          ></v-skeleton-loader>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
  <modal-admin-account 
    v-model="edit" 
    :action="action_user" 
    :data="user" 
    :loading="loading_user" 
    :roles="roles"
  />
</div>
</template>
<script>
export default {
  name: 'DataAccounts',
  components:{
    ModalAdminAccount: () => import(
      /* webpackChunkName: "modal-admin-account" */
      './ModalAdminAccount.vue'
    ),
    ListMobile: () => import(
      /* webpackChunkName: "list-mobile" */
      './ListMobile.vue'
    ),
    BtnFiltros: () => import(
      /* webpackChunkName: "btn-filtros" */
      '@/widgets/BtnFiltros.vue'
    ),
  },
  props:{
    headers: {
      type: Array,
      default: () => ([]),
    },
    roles: {
      type: Array,
      default: () => ([]),
    },
    data: {
      type: Array,
      default: () => ([]),
    },
    loadingData: Boolean,
  },
  data: () => ({
    attrs: {
      boilerplate: false,
      loading: true,
    },
    edit: false,
    action_user: 'crear',
    user: {},
    loading_user: false,
    search: '',
    items_filtro_sesion:[
      {title: 'Abierta', value: 'abierta'},
      {title: 'Cerrada', value: 'cerrada'},
    ],
    items_filtro_status:[
      {title: 'Usuario Activo', value: 'activado'},
      {title: 'Usuario Inactivo', value: 'inactivo'},
    ],
    filtrar:{
      sesion: '',
      status_user: '',
      rol: [],
    }

  }),
  methods:{    
    crearUser() {
      this.user = {};
      this.action_user = 'crear';
      this.edit = true;
    },
    gestionItem(item) {
      this.user = {};
      this.action_user = 'editar';
      this.user = Object.assign({}, item);
      this.edit = true;
    },
    updateData() {
      this.$emit('procesado')
    },
    async closeSesion(user) {
      const confirmar = await this.$root.$confirm(
        '¿Estas Seguro?', 
        'Desea cerra la Sesión del usuario '+ user.name,
        {
          type: 'confirm',
          btnConfirmText: 'Si, Cerrar',
          btnCancelText: 'Descartar',
        }
      );
      if(confirmar) {
        this.$emit('closeUser', user.id);
      }
    }
  },
  computed: {
     itemsUsersAdmin () {
      let dataUsers = [];
      dataUsers = this.data.map((item) => {
        return {
          ...item,
          status_user: item.status === 0 ? 'inactivo' : 'activado',
          sesion: item.sesion_activa === true ? 'abierta' : 'cerrada',
          rol: item.roles.map(rol => rol.name),
        }
      })

      const CustomFiltersKeys = Object.keys(this.filtrar)
      return dataUsers.filter((item) => {
        return CustomFiltersKeys.every(keyFiltro => {
          if(this.filtrar[keyFiltro].length === 0) {
            return true;
          }
          if(typeof this.filtrar[keyFiltro] === 'object' && typeof item[keyFiltro] === 'object') {
            return this.filtrar[keyFiltro].some(element => item[keyFiltro].includes(element));
          }
          return typeof this.filtrar[keyFiltro] === 'object' 
            ? this.filtrar[keyFiltro].includes(item[keyFiltro]) 
            : (this.filtrar[keyFiltro].toString()).toLowerCase().includes((item[keyFiltro].toString()).toLowerCase())
        })
      })
    },
    items_filtro_rol() {
      return this.roles.length > 0
      ? this.roles.map(rol => {
        return {
          title: rol.name,
          value: rol.name,
        }
      })
      : []
    },
  }
}
</script>
<style lang="sass" scoped>
.border-user
  width: 0
  height: 10px
  border-left: 0px solid transparent
  border-right: 16px solid transparent
  border-top: 15px solid #43b02a
  position: absolute
  margin-left: -16px
  opacity: .7
</style>
